import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { GetLearningUseCase } from '../../../../core/usecases/get-learning-use-case';
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { TooltipDirective } from '../../../shared/directives/tooltip/tooltip.directive';
import { SharedProgressBarComponent } from '../../shared/shared-progress-bar/shared-progress-bar.component';
import { onboardingLogToMixpanelUtility } from '../../shared/utilities/onboarding-log-to-mixpanel.utility';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';
import { SelectCurrentActiveUserStepIndex } from '../../state-manager/selectors/selector-names';
import { OnboardingLearnWithUsMapper } from './mappers/onboarding-learn-with-us.mapper';
import {
  OnboardingLearnWithUsCourse,
  OnboardingLearnWithUsState,
} from './models/onboarding-learn-with-us.state';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

@Component({
  selector: 'app-onboarding-learn-with-us',
  templateUrl: './onboarding-learn-with-us.component.html',
  styleUrls: ['./onboarding-learn-with-us.component.scss'],
  standalone: true,
  imports: [NgIf, TooltipDirective, NgFor, NgClass, SharedProgressBarComponent, AsyncPipe],
})
export class OnboardingLearnWithUsComponent extends BaseComponent implements OnInit {
  @ViewChild('learnContainerElement', { static: false, read: ViewContainerRef })
  public learnContainerElementRef: ViewContainerRef;

  public assetsPath = '../../../../../assets/img/on-boarding/';

  /**
   * State where the UI bind to
   */
  public state: OnboardingLearnWithUsState;

  /**
   * Tooltip component reference
   */
  public tooltipComponentRef = 'OnboardingNextStepTooltipComponent';

  /**
   * Current active index
   */
  public currentActiveStepSelector$: Observable<number>;

  /**
   * First course
   */
  public firstCourse: OnboardingLearnWithUsCourse;

  public isOnboardingV2Enabled = false;

  constructor(
    private _getLearningUseCase: GetLearningUseCase,
    private _learnWithUsMapper: OnboardingLearnWithUsMapper,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
  ) {
    super();
    this.currentActiveStepSelector$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectCurrentActiveUserStepIndex,
    );
  }

  ngOnInit(): void {
    this._getLearningUseCase
      .execute()
      .pipe(map((learning) => this._learnWithUsMapper.map(learning)))
      .pipe(this.takeUntilIsDestroyed())
      .subscribe({
        next: (state) => {
          this.state = state;
          [this.firstCourse] = this.state.courses;
          this.isVisible = true;
          const eventName = 'onboarding_learning_banner_load';
          onboardingLogToMixpanelUtility(
            this._logMixpanelEventUseCase,
            eventName,
            () => window.innerWidth >= 992,
          );
          if (typeof _cio !== 'undefined') {
            _cio.track(eventName);
          }
          this._changeDetectorRef.detectChanges();
        },
      });
    this.checkOnboardingV2();
  }

  checkOnboardingV2() {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.ONBOARDING_V2).subscribe({
      next: (flag) => {
        this.isOnboardingV2Enabled = flag;
      },
    });
  }

  public goToCourse(course: any) {
    const eventName = 'onboarding_learning_banner_course_click';
    const payload = { course_id: course.id, course_name: course.name };
    onboardingLogToMixpanelUtility(
      this._logMixpanelEventUseCase,
      eventName,
      () => window.innerWidth >= 992,
      payload,
    );
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName, payload);
    }
    this._router.navigate([`/onboarding/learning/course/${course.id}`]);
  }
}
