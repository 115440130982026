import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { Learning } from '../domain/learning.model';
import { LearningRepository } from '../repositories/learning.repository';

@Injectable({
  providedIn: 'any',
})
export class GetLearningUseCase implements UseCase<void, Observable<Learning>> {
  constructor(private repository: LearningRepository) {}

  execute(): Observable<Learning> {
    return this.repository.getLearning();
  }
}
