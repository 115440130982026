import {
  ComponentRef,
  Directive,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { TooltipOutletComponent } from '../../components/tooltip-outlet/tooltip-outlet.component';
import {
  ToolTipDirectiveCaretConfig,
  ToolTipDirectiveDimensions,
  ToolTipDirectiveInjectionPayload,
  ToolTipDirectiveParentStyling,
  ToolTipDirectivePosition,
} from './interfaces';

/**
 * So, this directive will primarily focus on attaching and detaching any component, onto a
 * host, as part of the host's tooltip.
 *
 * The choice of having this functionality as a directive stems from the fact that it is possible
 * for us to attach more functionality onto the hosts as needed.
 */

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnChanges, OnDestroy {
  /**
   * Whether the tooltip is shown or not. We will be listening for the status in the ngOnChanges,
   * this way, we don't need to side effects here.
   */
  @Input() appTooltipIsShown: boolean;

  /**
   * Set of configurations for the caret
   */
  @Input() appTooltipCaretConfig: ToolTipDirectiveCaretConfig;

  /**
   * This will be the component name which we will be injecting into the overlay. Of course, there
   * are other options of injection, but to make things a bit cleaner, we
   */
  @Input() appTooltipInjectionPayload: ToolTipDirectiveInjectionPayload;

  /**
   * The element where we want to attach the overlay
   */
  @Input() appTooltipConnectedElement: ViewContainerRef;

  /**
   * The element where the tooltip is attached to, for example, a div, a button
   * or a parent div
   */
  @Input() appTooltipAttachedToElement: HTMLElement;

  /**
   * The styling for the tooltip, as per the current usage
   * context
   */
  @Input() appTooltipStyling: { [style: string]: any };

  /**
   * Set of connected positions, needed to position the overlay
   */
  @Input() appTooltipOverlayPosition: ToolTipDirectivePosition;

  /**
   * Dimensions of tooltip in terms of width and height
   */
  @Input() appTooltipDimensions: ToolTipDirectiveDimensions;

  /**
   * Whether tooltip has backdrop or not
   */
  @Input() appTooltipHasBackdrop: boolean;

  /**
   * The custom stlying to apply to the parent element, and this is
   * optional
   */
  @Input() appTooltipParentCustomStyling: ToolTipDirectiveParentStyling;

  /**
   * A reference to the injected tooltip
   */
  private _injectedElementComponentRef: ComponentRef<TooltipOutletComponent>;

  private _positionalChangeListener!: any;

  private _checkedFor: number;

  private _maxListeningChecks: number;

  constructor() {
    this._checkedFor = 0;
    this._maxListeningChecks = 10;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.appTooltipAttachedToElement) {
      /**
       * If there is no parent element to which this tooltip should be attached,
       * then in that case, we will stop any additional operations, such as
       * instantiating the element, listening with the mutation obeserver etc.
       */
      return;
    }
    if (changes.appTooltipIsShown) {
      if (changes.appTooltipIsShown.currentValue === true) {
        this._injectPortalOntoOverlay();
        this._listenForPositionalChanged();
      } else {
        this._removeTheOverlay();
      }
    }
  }

  private _listenForPositionalChanged(): void {
    let startingPosition: DOMRect = this.appTooltipAttachedToElement.getBoundingClientRect();
    this._positionalChangeListener = setInterval(() => {
      const currentPosition: DOMRect = this.appTooltipAttachedToElement.getBoundingClientRect();
      if (this._checkedFor <= this._maxListeningChecks) {
        this._checkedFor += 1;
      }
      if (this._checkedFor > this._maxListeningChecks) {
        return;
      }
      if (
        startingPosition.x !== currentPosition.x ||
        startingPosition.y !== currentPosition.y ||
        currentPosition.height !== startingPosition.height ||
        currentPosition.width !== currentPosition.height
      ) {
        this._updateParentPosition();
        startingPosition = currentPosition;
        this._scrollToParentElementPosition();
      }
    }, 100);
  }

  /**
   * Here, we will be injecting the tooltip outlet, into the UI,
   * and we will be passing into it, the configurable properties.
   *
   * These will be completely agnostic, since in the end, we don't
   * know, how the hosts of these tooltips will be consuming them.
   */
  private _injectPortalOntoOverlay(): void {
    if (!this.appTooltipConnectedElement) {
      return;
    }
    this._injectedElementComponentRef =
      this.appTooltipConnectedElement.createComponent(TooltipOutletComponent);
    this._injectedElementComponentRef.instance.appTooltipCaretConfig = this.appTooltipCaretConfig;
    this._injectedElementComponentRef.instance.appTooltipOverlayPosition =
      this.appTooltipOverlayPosition;
    this._injectedElementComponentRef.instance.styling = this.appTooltipStyling;
    this._injectedElementComponentRef.instance.data = this.appTooltipInjectionPayload.data;
    this._injectedElementComponentRef.instance.componentToInject =
      this.appTooltipInjectionPayload.componentToInject;
    this._injectedElementComponentRef.instance.hasBackdrop = this.appTooltipHasBackdrop;
    this._injectedElementComponentRef.instance.dimensions = this.appTooltipDimensions;
    this._injectedElementComponentRef.instance.parentStyling = this.appTooltipParentCustomStyling;
    this._injectedElementComponentRef.changeDetectorRef.detectChanges();
    this._updateParentPosition();
    this._scrollToParentElementPosition();
  }

  /**
   * Shared method to inject the overlay position, either on intialization
   * or on events like browser events resize.
   */
  private _updateParentPosition(): void {
    this._injectedElementComponentRef?.instance.parentDomRect$.next(
      this.appTooltipAttachedToElement.getBoundingClientRect(),
    );
  }

  ngOnDestroy(): void {
    this._clearInterval();
  }

  /**
   * We will need to scroll the user in the UI to the section that they should actually
   * be viewing the tooltip.
   *
   * We will have priority on the tooltip element
   */
  private _scrollToParentElementPosition(): void {
    /**
     * If the tooltip is placed at bottom of parent, scroll to bottom of
     * tooltip
     */
    const { height } =
      this._injectedElementComponentRef.instance._tooltipWrapperElement.nativeElement.getBoundingClientRect();
    const cumulativeTooltipHeight = height + this.appTooltipCaretConfig.height;
    const parentDims: DOMRect =
      this.appTooltipConnectedElement.element.nativeElement.getBoundingClientRect();
    if (this.appTooltipOverlayPosition.positionOnOrigin === 'bottom') {
      /**
       * If placed at the bottom, and tooltip is possibly clipped, then scroll
       * down
       */
      const { innerHeight } = window;
      const cumulativeTooltipBottom = parentDims.top + parentDims.height + cumulativeTooltipHeight;
      if (cumulativeTooltipBottom > innerHeight) {
        const overlapOffset = (cumulativeTooltipBottom - innerHeight) * 2;
        window.scrollTo(0, overlapOffset);
      }
      this._injectedElementComponentRef.instance._tooltipWrapperElement.nativeElement.scrollIntoView();
    } else if (this.appTooltipOverlayPosition.positionOnOrigin === 'top') {
      /**
       * If tooltip is placed at top of parent, scroll to top of tooltip
       */
      this.appTooltipConnectedElement.element.nativeElement.scrollIntoView({
        block: 'end',
      });
    }
  }

  @HostListener('window:resize')
  onDocumentWindowResize(): void {
    this._updateParentPosition();
  }

  @HostListener('window:scroll')
  onDocumentWindowScroll(): void {
    this._updateParentPosition();
  }

  /**
   * As we no longer need it, so we can remove the overlay from the DOM, and hence it's
   * enclosing contents.
   */
  private _removeTheOverlay(): void {
    if (this.appTooltipConnectedElement) {
      this.appTooltipConnectedElement.clear();
    }
    if (this._positionalChangeListener) {
      this._clearInterval();
    }
  }

  private _clearInterval(): void {
    clearInterval(this._positionalChangeListener);
  }
}
