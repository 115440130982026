import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-shared-progress-bar',
  templateUrl: './shared-progress-bar.component.html',
  styleUrls: ['./shared-progress-bar.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule],
})
export class SharedProgressBarComponent {
  @Input() progressScore = 90;
}
