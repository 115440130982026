import { NgFor, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { getOnboardingCashback } from '@features/onboarding/data';
import { TranslateModule } from '@ngx-translate/core';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { ActionSetActiveStep } from '../../state-manager/actions/actions-names';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';

@Component({
  selector: 'app-onboarding-next-step-tooltip',
  templateUrl: './onboarding-next-step-tooltip.component.html',
  styleUrls: ['./onboarding-next-step-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgFor, NgIf, TranslateModule],
})
export class OnboardingNextStepTooltipComponent implements OnInit {
  /**
   * Data for this component to work with, and this can be
   * optional
   */
  @Input() data: any;

  currentCurrency: string;

  public amount = getOnboardingCashback();

  constructor(
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _multitenancyService: MultitenancyService,
  ) {
    this.currentCurrency = this._multitenancyService.getCurrentCountry().currency.arabicName;
  }

  ngOnInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  /**
   * The user can either navigat forward (for now) but backward as well (maybe in future)
   */
  public navigateToStep(direction: 'next' | 'previous'): void {
    if (direction === 'next') {
      this._goToNext();
    } else {
      this._goToPrevious();
    }
  }

  private _goToNext(): void {
    /**
     * Go to next has the option to navigate
     */
    if (this.data.navigateTo) {
      this._navigateTo();
    }
    this._onboardingStoryGuideStateManager.updateStatePiece(
      ActionSetActiveStep,
      this.data.currentStep + 1,
    );
  }

  private _goToPrevious(): void {
    // TODO
  }

  /**
   * A tooltip is optionally a means to navigating to another path, so
   * if we have navigation data on our tooltip, then we can safely
   * navigate to this path.
   */
  private _navigateTo(): void {
    this._router.navigate([this.data.navigateTo.link]);
  }
}
