import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { OnboardingNextStepTooltipComponent } from 'src/app/presentation/onboarding/shared/onboarding-next-step-tooltip/onboarding-next-step-tooltip.component';
import {
  ToolTipDirectiveCaretConfig,
  ToolTipDirectiveDimensions,
  ToolTipDirectiveParentStyling,
  ToolTipDirectivePosition,
} from '../../directives/tooltip/interfaces';
import { getUserAgentDirectionalityUtility } from '../../utilities/get-user-agent-directionality.utility';

/**
 * This will be an outlet for content disposition, you can think of this as
 * a portal, where we will be injecting other components.
 *
 * It will heavily rely on the configurable properties which we will be passing
 * into it.
 */

interface TooltipOutletPosition {
  /**
   * top position
   */
  top: string;

  /**
   * right position
   */
  right: string;

  /**
   * bottom position
   */
  bottom: string;

  /**
   * left position
   */
  left: string;
}

/**
 * An object which we can pass in as the styling
 */
interface TooltipOutletStyling {
  [style: string]: any;
}

const INJECTABLE_COMPONENTS_MAPPING: any = {
  OnboardingNextStepTooltipComponent,
};

@Component({
  styleUrls: ['tooltip-outlet.component.scss'],
  templateUrl: 'tooltip-outlet.component.html',
  standalone: true,
  imports: [NgIf],
})
export class TooltipOutletComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Styling object, which we will apply to the outlet
   */
  @Input() styling: TooltipOutletStyling;

  /**
   * Set of configurations for the caret passed on from the
   * directive
   */
  @Input() appTooltipCaretConfig: ToolTipDirectiveCaretConfig;

  /**
   * This will be the inputs, which we will need to pass onto
   * the dynamic components that we need to inject into this
   * outlet.
   *
   * This can for example be the next step in the onboarding
   * process, a piece of text to show, an ng template to
   * render and so forth
   */
  @Input() data: any;

  /**
   * DOM rect for the parent
   */
  @Input() parentDomRect$: BehaviorSubject<DOMRect>;

  /**
   * Set of connected positions, needed to position the overlay
   */
  @Input() appTooltipOverlayPosition: ToolTipDirectivePosition;

  /**
   * Component to inject into tooltip outlet
   */
  @Input() componentToInject: string;

  /**
   * Tooltip dimensions
   */
  @Input() dimensions: ToolTipDirectiveDimensions;

  /**
   * Whether the tooltip has a backdrop or not
   */
  @Input() hasBackdrop: boolean;

  /**
   * parent styling
   */
  @Input() parentStyling: ToolTipDirectiveParentStyling;

  @ViewChild('tooltipWrapperElement', { static: true })
  public _tooltipWrapperElement: ElementRef<HTMLDivElement>;

  /**
   * Caret element ref
   */
  @ViewChild('caretElement', { static: true }) private _caretElement: ElementRef<HTMLDivElement>;

  @ViewChild('injectionOutlet', { static: true, read: ViewContainerRef })
  private _injectionOutlet: ViewContainerRef;

  @ViewChild('backdropPiercingTemplate', { static: false })
  private _backdropPiercingTemplate: ElementRef<HTMLDivElement>;

  /**
   * The dom rect for this instance
   */
  private _wrapperBoundingClient: DOMRect;

  /**
   * Listen for on destroy action
   */
  private _ondestroy$: Subject<boolean>;

  constructor(private _renderer2: Renderer2, public elementRef: ElementRef) {
    this._ondestroy$ = new Subject<boolean>();
    this.parentDomRect$ = new BehaviorSubject<DOMRect>({} as any);
  }

  ngOnInit(): void {
    this._injectComponentRef();
  }

  ngAfterViewInit() {
    this._listenForChangesFromParent();
  }

  ngOnDestroy(): void {
    this._ondestroy$.next(true);
    this._ondestroy$.complete();
    this._injectionOutlet.clear();
  }

  private _listenForChangesFromParent(): void {
    this._applyStyling(this._tooltipWrapperElement.nativeElement, this.dimensions);
    this.parentDomRect$.pipe(takeUntil(this._ondestroy$)).subscribe({
      next: (newPosition) => {
        if (newPosition.top !== undefined) {
          this._computePositionalStyling(newPosition);
          this._applyStyling(this._tooltipWrapperElement.nativeElement, this.styling);
          if (this.hasBackdrop) {
            this._renderPiercing(newPosition);
          }
        }
      },
    });
  }

  private _injectComponentRef(): void {
    const componentRef: any = this._injectionOutlet.createComponent(
      INJECTABLE_COMPONENTS_MAPPING[this.componentToInject],
    );
    componentRef.instance.data = this.data;
  }

  private _applyStyling(element: any, styles: { [style: string]: any }): void {
    for (const style in styles) {
      if (style in styles) {
        this._renderer2.setStyle(element, style, styles[style]);
      }
    }
  }

  /**
   * Calculate positional styling of tooltip
   */
  private _computePositionalStyling(newPosition: DOMRect): void {
    this._wrapperBoundingClient = this._tooltipWrapperElement.nativeElement.getBoundingClientRect();
    switch (this.appTooltipOverlayPosition.positionOnOrigin) {
      case 'top':
        this._computePositionalStylingOnTopOfOrigin(newPosition);
        break;
      case 'bottom':
        this._computePositionalStylingAtBottomOfOrigin(newPosition);
        break;
      case 'right':
        this._computePositionalStylingAtRightOfOrigin(newPosition);
        break;
      case 'left':
        this._computePositionalStylingAtLeftOfOrigin(newPosition);
        break;
    }

    // Now render the caret
    this._renderCaret();
  }

  /**
   * When the position is on top of the origin
   */
  private _computePositionalStylingOnTopOfOrigin(newPosition: DOMRect): void {
    this.styling.top = `${
      newPosition.top -
      this._wrapperBoundingClient.height -
      this.appTooltipCaretConfig.height / 2 -
      this.appTooltipCaretConfig.offsetFromHostElement
    }px`;
    if (this.appTooltipOverlayPosition.placementX === 'center') {
      /**
       * We want to place it at the center
       */
      this.styling.left = `calc(${
        newPosition.x + newPosition.width / 2 - this._wrapperBoundingClient.width / 2
      }px)`;
    } else {
      const cumulativePlacementX = `calc(${this.appTooltipOverlayPosition.placementX} + ${newPosition.x}px`;
      /**
       * Place it at any other position other than the center
       */
      if (getUserAgentDirectionalityUtility() === 'rtl') {
        this.styling.left = cumulativePlacementX;
      } else {
        this.styling.right = cumulativePlacementX;
      }
    }
  }

  private _computePositionalStylingAtBottomOfOrigin(newPosition: DOMRect): void {
    this.styling.top = `${
      newPosition.y + newPosition.height + this.appTooltipCaretConfig.offsetFromHostElement
    }px`;
    if (this.appTooltipOverlayPosition.placementX === 'center') {
      /**
       * We want to place it at the center
       */
      this.styling.left = `calc(${
        newPosition.x + newPosition.width / 2 - this._wrapperBoundingClient.width / 2
      }px)`;
    } else {
      const cumulativePlacementX = `calc(${this.appTooltipOverlayPosition.placementX} + ${newPosition.x}px`;
      /**
       * Place it at any other position other than the center
       */
      if (getUserAgentDirectionalityUtility() === 'rtl') {
        this.styling.left = cumulativePlacementX;
      } else {
        this.styling.right = cumulativePlacementX;
      }
    }
  }

  private _computePositionalStylingAtRightOfOrigin(newPosition: DOMRect): void {
    this.styling.top = `calc(${newPosition.top - this.parentStyling.padding}px + ${
      this.appTooltipOverlayPosition.placementY
    })`;
    if (getUserAgentDirectionalityUtility() === 'rtl') {
      this.styling.left = `${
        newPosition.right +
        this.appTooltipCaretConfig.width +
        this.appTooltipCaretConfig.offsetFromHostElement
      }px`;
    } else {
      this.styling.right = `${
        newPosition.right +
        this.appTooltipCaretConfig.width +
        this.appTooltipCaretConfig.offsetFromHostElement
      }px`;
    }
  }

  private _computePositionalStylingAtLeftOfOrigin(newPosition: DOMRect): void {
    this.styling.top = `calc(${newPosition.top - this.parentStyling.padding}px + ${
      this.appTooltipOverlayPosition.placementY
    })`;
    if (getUserAgentDirectionalityUtility() === 'rtl') {
      this.styling.left = `${
        newPosition.left -
        this._wrapperBoundingClient.width -
        this.appTooltipCaretConfig.width -
        this.appTooltipCaretConfig.offsetFromHostElement -
        this.parentStyling.padding
      }px`;
    } else {
      this.styling.right = `${
        newPosition.right -
        this._wrapperBoundingClient.width -
        this.appTooltipCaretConfig.width -
        this.appTooltipCaretConfig.offsetFromHostElement -
        this.parentStyling.padding
      }px`;
    }
  }

  /**
   *
   * @returns
   *
   * Optionally render the caret at the desired position
   */
  private _renderCaret(): void {
    if (!this.appTooltipCaretConfig.hasCaret) {
      /**
       * If there is no caret, we will not render anything
       */
      return;
    }

    switch (this.appTooltipCaretConfig.positionOnOrigin) {
      case 'bottom':
        return this._renderCaretAtTheBottom();
      case 'top':
        return this._renderCaretAtTheTop();
      case 'left':
        return this._renderCaretAtTheLeft();
      case 'right':
        return this._renderCaretAtTheRight();
    }
  }

  private _renderCaretAtTheBottom(): void {
    this.styling.top = `calc(${this.styling.top} - ${this.appTooltipCaretConfig.height}px)`;
    let caretXAxisPlacement: { [xAxisOrigin in 'left' | 'right']?: string };
    if (this.appTooltipCaretConfig.placementX === 'center') {
      caretXAxisPlacement = { left: `calc(50% - ${this.appTooltipCaretConfig.width / 2}px)` };
    } else {
      if (getUserAgentDirectionalityUtility() === 'rtl') {
        caretXAxisPlacement = { left: this.appTooltipCaretConfig.placementX };
      } else {
        caretXAxisPlacement = { right: this.appTooltipCaretConfig.placementX };
      }
    }
    this._applyStyling(this._caretElement.nativeElement, {
      'border-left': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-right': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-top': `${this.appTooltipCaretConfig.height}px solid ${this.appTooltipCaretConfig.color}`,
      bottom: `-${this.appTooltipCaretConfig.height}px`,
      ...caretXAxisPlacement,
    });
  }

  private _renderCaretAtTheTop(): void {
    this.styling.top = `calc(${this.styling.top} + ${this.appTooltipCaretConfig.height}px)`;
    let carerXAxisPlacement: { [xAxisOrigin in 'left' | 'right']?: string };
    if (this.appTooltipCaretConfig.placementX === 'center') {
      carerXAxisPlacement = { left: `calc(50% - ${this.appTooltipCaretConfig.width / 2}px)` };
    } else {
      if (getUserAgentDirectionalityUtility() === 'ltr') {
        carerXAxisPlacement = { left: this.appTooltipCaretConfig.placementX };
      } else {
        carerXAxisPlacement = { right: this.appTooltipCaretConfig.placementX };
      }
    }
    this._applyStyling(this._caretElement.nativeElement, {
      'border-left': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-right': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-bottom': `${this.appTooltipCaretConfig.height}px solid ${this.appTooltipCaretConfig.color}`,
      top: `-${this.appTooltipCaretConfig.height}px`,
      ...carerXAxisPlacement,
    });
  }

  private _renderCaretAtTheLeft(): void {
    this._applyStyling(this._caretElement.nativeElement, {
      'border-top': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-bottom': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-right': `${this.appTooltipCaretConfig.height}px solid ${this.appTooltipCaretConfig.color}`,
      top: `${this.appTooltipCaretConfig.placementY}`,
      left: `-${this.appTooltipCaretConfig.width}px`,
    });
  }

  private _renderCaretAtTheRight(): void {
    this._applyStyling(this._caretElement.nativeElement, {
      'border-top': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-bottom': `${this.appTooltipCaretConfig.width}px solid transparent`,
      'border-left': `${this.appTooltipCaretConfig.height}px solid ${this.appTooltipCaretConfig.color}`,
      top: `${this.appTooltipCaretConfig.placementY}`,
      right: `-${this.appTooltipCaretConfig.width}px`,
    });
  }

  private _renderPiercing(newPosition: DOMRect): void {
    const productsElement = document.body;
    const scrollDiff = productsElement.scrollWidth - productsElement.clientWidth;

    const { left, width, top, height } = newPosition;
    const roundPadding: number = this.parentStyling?.padding || 0;

    /**
     * The width of the cutout will be width of target plus twice
     * the padding
     */
    const cutoutWidth = width + roundPadding * 2;

    /**
     * Height of cutout will be height of target plust twice the
     * padding
     */
    const cutoutHeight = height + roundPadding * 2;

    /**
     * Cutout start X will be target element startX less padding / 2
     */
    const cutoutStartX = left - roundPadding + (scrollDiff > 0 ? scrollDiff : 0);

    /**
     * Cutout start Y will be target element startY less padding / 2
     */
    const cutoutStartY = top - roundPadding;

    const styles = {
      left: `${cutoutStartX}px`,
      width: `${cutoutWidth}px`,
      top: `${cutoutStartY}px`,
      height: `${cutoutHeight}px`,
    };
    this._applyStyling(this._backdropPiercingTemplate.nativeElement, styles);
  }
}
