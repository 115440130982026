<div class="wrapper">
  <h1
    [ngStyle]="data.uiContent.title.styling"
    class="{{ data.uiContent.title.classes }} wrapper__title"
  >
    <span [ngStyle]="section.styling" *ngFor="let section of data.uiContent.title.sections">
      {{ section.text | translate : { currentCurrency, amount } }}
    </span>
  </h1>

  <div class="wrapper__content">
    <p
      [ngStyle]="paragraph.styling"
      class="{{ paragraph.classes }}"
      *ngFor="let paragraph of data.uiContent.body.paragraphs"
    >
      <span [ngStyle]="span.styling" *ngFor="let span of paragraph.sections">
        {{ span.text | translate }}
      </span>
    </p>
  </div>

  <div [ngStyle]="data.uiContent.footer.styling" class="wrapper__footer">
    <div
      [ngStyle]="button.styling"
      class="wrapper__footer__button"
      *ngFor="let button of data.uiContent.footer.buttons"
      (click)="navigateToStep(button.action)"
    >
      <ng-container *ngFor="let buttonSection of button.sections">
        <div
          *ngIf="buttonSection.type === 'image'"
          class="wrapper__footer__button__arrow"
          [ngStyle]="buttonSection.styling"
        >
          <img src="{{ buttonSection.content }}" alt="{{ button.action }}" />
        </div>
        <div
          *ngIf="buttonSection.type === 'text'"
          class="{{ data.uiContent.footer.classes }} wrapper__footer__button__text"
          [ngStyle]="buttonSection.styling"
        >
          {{ buttonSection.content | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
