import { Inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { map, Observable } from 'rxjs';
import { orderRepositoryInjectionToken } from 'src/app/data/injection-tokens/order-repository.injection-token';
import {
  ONBOARDING_EXPERIMENT_START_DATE,
  ONBOARDING_MAX_ORDER_PROGRESS,
} from '../../presentation/shared/constants/onboarding';
import { UseCase } from '../base/use-case';
import { OrderStep, OrdersTracking } from '../domain/orders-tracking.model';
import { OrderRepository } from '../repositories/order.repository';

@Injectable({
  providedIn: 'any',
})
export class GetOnboardingOrdersTrackingUseCase
  implements UseCase<void, Observable<OrdersTracking>>
{
  constructor(@Inject(orderRepositoryInjectionToken) private _orderRepository: OrderRepository) {}

  execute(): Observable<OrdersTracking> {
    const filter = {
      pageSize: ONBOARDING_MAX_ORDER_PROGRESS,
      country: country.data,
      fromDate: ONBOARDING_EXPERIMENT_START_DATE,
      toDate: new Date(),
      status: 'delivered',
    };
    return this._orderRepository.getOrdersTracking(filter).pipe(map(this.onlyDeliveredOrders));
  }

  private onlyDeliveredOrders(ordersTracking: OrdersTracking): OrdersTracking {
    const deliveredOrders = ordersTracking.orders.filter(
      (order) => order.currentStep >= OrderStep.Delivered,
    );
    return { orders: deliveredOrders };
  }
}
