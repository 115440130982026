import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';

/**
 * We are doing a couple of event logging to mixpanel, with respect to the
 * onboarding, and these depend on a couple of conditions, so we can
 * localize the tracking here.
 */
export const onboardingLogToMixpanelUtility = (
  logMixpanelEventUseCase: LogMixpanelEventUseCase,
  eventName: string,
  canLog: Function,
  payload?: any,
) => {
  if (canLog()) {
    logMixpanelEventUseCase.execute({ eventName, payload });
  }
};
