<div
  class="learn-container"
  *ngIf="isVisible && !isOnboardingV2Enabled"
  #learnContainerElement
  appTooltip
  [appTooltipIsShown]="
    (currentActiveStepSelector$ | async) === 1 && learnContainerElementRef !== undefined
  "
  [appTooltipCaretConfig]="{
    hasCaret: true,
    height: 37,
    width: 37,
    positionOnOrigin: 'bottom',
    placementX: '40px',
    color: 'white',
    offsetFromHostElement: 20
  }"
  [appTooltipInjectionPayload]="{
    componentToInject: tooltipComponentRef,
    data: {
      currentStep: 1,
      uiContent: {
        title: {
          classes: 'heading2--bold',
          styling: {
            'column-gap': '8px'
          },
          sections: [
            {
              text: 'ONBOARDING.STEPS.1.HEADER.a',
              styling: {
                color: '#0F1933'
              }
            },
            {
              text: 'ONBOARDING.STEPS.1.HEADER.b',
              styling: {
                color: '#46B8B0'
              }
            }
          ]
        },
        body: {
          paragraphs: [
            {
              classes: 'body2--regular',
              styling: {
                'text-align': 'initial'
              },
              sections: [
                {
                  text: 'ONBOARDING.STEPS.1.BODY.a',
                  styling: {
                    'font-weight': '400',
                    color: '#0F1933'
                  }
                },
                {
                  text: 'ONBOARDING.STEPS.1.BODY.b',
                  styling: {
                    'font-weight': '700',
                    color: '#0F1933'
                  }
                },
                {
                  text: 'ONBOARDING.STEPS.1.BODY.c',
                  styling: {
                    'font-weight': '400',
                    color: '#0F1933'
                  }
                }
              ]
            }
          ]
        },
        footer: {
          classes: 'body2--medium',
          styling: {
            'justify-content': 'flex-end'
          },
          buttons: [
            {
              action: 'next',
              styling: {
                padding: '16px 48px',
                'column-gap': '8px'
              },
              sections: [
                {
                  type: 'text',
                  content: 'ONBOARDING.STEPS.1.FOOTER.a'
                },
                {
                  type: 'image',
                  content: assetsPath + 'next-step-left-arrow.svg'
                }
              ]
            }
          ]
        }
      }
    }
  }"
  [appTooltipConnectedElement]="learnContainerElementRef"
  [appTooltipAttachedToElement]="learnContainerElement"
  [appTooltipStyling]="{
    'background-color': 'white',
    'border-radius': '10px'
  }"
  [appTooltipOverlayPosition]="{
    positionOnOrigin: 'top',
    placementX: '10px'
  }"
  [appTooltipDimensions]="{
    width: '502px'
  }"
  [appTooltipHasBackdrop]="true"
  [appTooltipParentCustomStyling]="{
    padding: 20
  }"
>
  <div
    class="learn-container__header learn-row"
    #learnContainerElement
    appTooltip
    [appTooltipIsShown]="
      (currentActiveStepSelector$ | async) === 5 && learnContainerElementRef !== undefined
    "
    [appTooltipCaretConfig]="{
      hasCaret: true,
      height: 37,
      width: 37,
      positionOnOrigin: 'bottom',
      placementX: 'center',
      color: 'white',
      offsetFromHostElement: 20
    }"
    [appTooltipInjectionPayload]="{
      componentToInject: tooltipComponentRef,
      data: {
        currentStep: 5,
        navigateTo: {
          link: 'onboarding/learning/course/' + firstCourse.id
        },
        uiContent: {
          title: {
            classes: 'heading2--bold',
            styling: {
              'column-gap': '8px'
            },
            sections: [
              {
                text: 'ONBOARDING.STEPS.5.HEADER.a',
                styling: {
                  color: '#0F1933'
                }
              }
            ]
          },
          body: {
            paragraphs: [
              {
                classes: 'body2--regular',
                styling: {
                  'text-align': 'initial'
                },
                sections: [
                  {
                    text: 'ONBOARDING.STEPS.5.BODY.a',
                    styling: {
                      'font-weight': '400',
                      color: '#0F1933'
                    }
                  }
                ]
              }
            ]
          },
          footer: {
            classes: 'body2--medium',
            styling: {
              'justify-content': 'flex-end'
            },
            buttons: [
              {
                action: 'next',
                styling: {
                  padding: '16px 35px',
                  'column-gap': '8px'
                },
                sections: [
                  {
                    type: 'text',
                    content: 'ONBOARDING.STEPS.5.FOOTER.a'
                  },
                  {
                    type: 'text',
                    content: 'ONBOARDING.STEPS.5.FOOTER.c'
                  },
                  {
                    type: 'text',
                    content: firstCourse.name
                  },
                  {
                    type: 'image',
                    content: assetsPath + 'next-step-left-arrow.svg'
                  }
                ]
              }
            ]
          }
        }
      }
    }"
    [appTooltipConnectedElement]="learnContainerElementRef"
    [appTooltipAttachedToElement]="learnContainerElement"
    [appTooltipStyling]="{
      'background-color': 'white',
      'border-radius': '10px'
    }"
    [appTooltipOverlayPosition]="{
      positionOnOrigin: 'top',
      placementX: '620px'
    }"
    [appTooltipDimensions]="{
      width: '502px'
    }"
    [appTooltipHasBackdrop]="true"
    [appTooltipParentCustomStyling]="{
      padding: 20
    }"
  >
    <div class="learn-container__header__right">
      <div class="learn-container__header__right__text">
        <img src="{{ assetsPath + 'apple.svg' }}" />
      </div>
      <div class="learn-container__header__rightht__image">
        <p class="learn-text-content heading2--bold">
          <span class="learn-text-primary heading2--bold">تعلم معنا </span>قبل ان تبدأ
        </p>
        <p class="learn-text-content-medium body1--regular">
          نود منك متابعة جميع أهدافك التعليمية لتصبح بائعًا أفضل.
        </p>
      </div>
    </div>
  </div>
  <div class="learn-container__body">
    <div
      [ngClass]="{
        'learn-container__body__card--inactive':
          (currentActiveStepSelector$ | async) === 5 && course.id !== firstCourse.id
      }"
      class="learn-container__body__card"
      *ngFor="let course of state.courses"
      (click)="goToCourse(course)"
    >
      <div class="learn-container__body__card__right">
        <img src="{{ course.image }}" />
      </div>
      <div class="learn-container__body__card__left">
        <p class="learn-text-content body1--bold">{{ course.name }}</p>
        <p class="learn-text-content-medium body2--regular">{{ course.progressText }}</p>
        <app-shared-progress-bar [progressScore]="course.progressScore"></app-shared-progress-bar>
      </div>
    </div>
  </div>
</div>
