import { Injectable } from '@angular/core';
import { OnboardingRewardType } from '../../../../../core/domain/feature-attributes.model';
import { OrdersTracking } from '../../../../../core/domain/orders-tracking.model';
import { OnboardingCurrentGoalProgressState } from '../models/onboarding-current-goal-progress.state';

@Injectable({
  providedIn: 'any',
})
export class OnboardingCurrentGoalProgressMapper {
  map(
    ordersTracking: OrdersTracking,
    rewardType: OnboardingRewardType,
    maxProgress: number,
  ): OnboardingCurrentGoalProgressState {
    const ordersCount = ordersTracking.orders.length;
    return {
      image: this.mapImage(rewardType),
      progressScore: (ordersCount / maxProgress) * 100,
      currentScore: ordersCount,
      maxScore: maxProgress,
    };
  }

  private mapImage(rewardType: OnboardingRewardType): string {
    switch (rewardType) {
      case OnboardingRewardType.Cashback:
        return 'treasure.svg';
      case OnboardingRewardType.Badge:
        return 'orders-progress-reward-badge.svg';
    }
  }
}
