<div class="wrapper" #tooltipWrapperElement>
  <div class="outlet">
    <ng-template #injectionOutlet></ng-template>
  </div>
  <div #caretElement class="caret"></div>
</div>

<div *ngIf="hasBackdrop" class="backdrop">
  <div #backdropPiercingTemplate class="piercing"></div>
</div>
