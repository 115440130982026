import { Injectable } from '@angular/core';
import { Learning } from '../../../../../core/domain/learning.model';
import { OnboardingLearnWithUsState } from '../models/onboarding-learn-with-us.state';

const getCompletedLessonsCount = (lessons: any): number =>
  lessons.filter((lesson: any) => lesson.isCompleted).reduce((count: number) => count + 1, 0);

@Injectable({
  providedIn: 'any',
})
export class OnboardingLearnWithUsMapper {
  map(learning: Learning): OnboardingLearnWithUsState {
    return {
      courses: learning.courses.map((course) => {
        const lessonsCount = course.lessons.length;
        const completedLessonsCount = getCompletedLessonsCount(course.lessons);
        return {
          id: course.id,
          image: course.iconUrl,
          name: course.name,
          progressText: `${completedLessonsCount} من ${lessonsCount}`,
          progressScore: (completedLessonsCount / lessonsCount) * 100,
        };
      }),
    };
  }
}
