import { country } from '@features/country/data';

export function getOnboardingCashback(): number {
  if (country.is('KWT') || country.is('OMN')) {
    return 5;
  }
  if (country.is('IRQ')) return 7000;

  return 50;
}
